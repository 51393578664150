import React, { useState } from 'react';
import './Navbar.css';

const Navbar = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    onSearch(searchTerm);
  };

  return (
    <>
      <nav className="navbar">
        <div className="navbar-title"><a href='/' style={{ textDecoration: "none", color: "white" }}>Indian MMS</a></div>
        <form onSubmit={handleSearch} className="navbar-search-form">
          <input
            type="text"
            value={searchTerm}
            onChange={handleInputChange}
            className="navbar-search-input"
            placeholder="Search..."
          />
          <button type="submit" className="navbar-search-button">
            Search
          </button>
        </form>
      </nav>
      <div className="navbar-image-container">
        <img src="https://i.ibb.co/hMkNKHL/head.jpg" alt="Background" />
      </div>
    </>
  );
};

export default Navbar;
