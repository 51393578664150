import React, { useState, useEffect, useRef } from 'react';
import './ScrollingPlaylists.css'; // For custom styles

const ScrollingPlaylists = ({ movies }) => {
  const [randomPlaylists, setRandomPlaylists] = useState([]);
  const [loadedImages, setLoadedImages] = useState({}); // Object to track loaded images
  const scrollContainer = useRef(null); // Reference for the container

  // Function to get unique random playlists
  const getRandomPlaylists = () => {
  const uniquePlaylists =[
      "https://i.ibb.co/0nMSYXJ/34.gif",
      "https://i.ibb.co/GdcN8dB/35.gif",
      "https://i.ibb.co/Yd7vg3c/36.gif",
      "https://i.ibb.co/hc6XP4p/3.gif",
      "https://i.ibb.co/JvSyB8N/5.gif",
      "https://i.ibb.co/5xRYB6N/7.gif",
      "https://i.ibb.co/PmBN5vH/8.gif",
      "https://i.ibb.co/KqYpYWR/9.gif",
      "https://i.ibb.co/tbXTzbD/12.gif",
      "https://i.ibb.co/fXw63g8/13.gif",
      "https://i.ibb.co/kHxTD80/14.gif",
      "https://i.ibb.co/wwRynGB/15.gif",
      "https://i.ibb.co/mBmjvQf/17.gif",
      "https://i.ibb.co/vPDZXMY/19.gif",
      "https://i.ibb.co/x20s8WR/22.gif",
      "https://i.ibb.co/XVnxP5b/23.gif",
      "https://i.ibb.co/cr2CYnM/24.gif",
      "https://i.ibb.co/nnzcL58/25.gif",
      "https://i.ibb.co/ZX6vrgm/28.gif",
      "https://i.ibb.co/H7ZWhSb/30.gif"
    ];
    return uniquePlaylists;
  };

  useEffect(() => {
    setRandomPlaylists(getRandomPlaylists());
  }, [movies]);

  // Create a version of the playlists array with duplicates but avoid fetching multiple times
  const doubledPlaylists = [...randomPlaylists, ...randomPlaylists, ...randomPlaylists, ...randomPlaylists];

  // Automatic scroll function
  useEffect(() => {
    const scrollInterval = setInterval(() => {
      if (scrollContainer.current) {
        scrollContainer.current.scrollLeft += 2;
      }
    }, 1); // Adjust the speed as needed

    return () => clearInterval(scrollInterval);
  }, []);

  const handleScrollStart = () => {
    if (scrollContainer.current) {
      clearInterval(scrollContainer.current.scrollInterval); // Stop automatic scroll
    }
  };

  const handleScrollEnd = () => {
    const scrollInterval = setInterval(() => {
      if (scrollContainer.current) {
        scrollContainer.current.scrollLeft += 2;
      }
    }, 10);
    scrollContainer.current.scrollInterval = scrollInterval; // Resume automatic scroll
  };

  const handleImageLoad = (src) => {
    setLoadedImages((prevLoadedImages) => ({ ...prevLoadedImages, [src]: true }));
  };

  return (
    <div>
      <div
        className="scrolling-playlists"
        ref={scrollContainer}
        onMouseEnter={handleScrollStart}
        onMouseLeave={handleScrollEnd}
        onTouchStart={handleScrollStart}
        onTouchEnd={handleScrollEnd}
      >
        {doubledPlaylists.map((src, index) => (
          <div key={index} className="playlist-card">
            {/* Render the image only if it has loaded */}
            <img
              src={src}
              alt={`gif-${index}`}
              className="playlist-img"
              onLoad={() => handleImageLoad(src)} // Track when the image loads
              style={{ visibility: loadedImages[src] ? 'visible' : 'hidden' }} // Show image only if loaded
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ScrollingPlaylists;
