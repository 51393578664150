import React from 'react';
import './Marquee.css'; // Ensure you're using your existing CSS for styling

const Marquee = () => {
  return (
    <div className="marquee-container">
      <div className="marquee-text">
        🎨 Want to create stunning custom images like the ones you see above👆? Unlock our exclusive Telegram bot now!
        <a href="https://t.me/+4CCYefyWKIhlNzQ1" target="_blank" rel="noopener noreferrer">
          Join the channel and get started!
        </a> 
        🚀 By joining, you'll also get access to **18,000+ leaked videos** and premium content that you won't find anywhere else. 
        <a href="https://t.me/+4CCYefyWKIhlNzQ1" target="_blank" rel="noopener noreferrer">
          Join now for exclusive access! 
        </a>
        Also get the login accees to below👇 videos
      </div>
    </div>
  );
};

export default Marquee;
