import React, { useState, useEffect, useRef } from 'react';
import './Detail.css';
import Card from './Card';
import Modal from './Modal';
import fluidPlayer from 'fluid-player';
import Login from './Login';

const getRandomItems = (movies, count) => {
  const shuffled = movies.sort(() => 0.5 - Math.random());
  return movies.slice(0, count);
};

const Detail = ({ post, onBackClick, data, handleCardClick, isLoggedIn, setShowLogin, handleLogin }) => {
  const [modalImage, setModalImage] = useState(null);
  const [checkLoggedIn, setcheckLoggedIn] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const videoRef = useRef(null);
  const playerRef = useRef(null); // Ref to hold FluidPlayer instance
  const [isVideoReady, setIsVideoReady] = useState(false); // New state to track if the video can be initialized

  // Initialize FluidPlayer when videoSrc and videoRef are both ready
  useEffect(() => {
    if (videoRef.current && post.videoSrc && isVideoReady) {
      // Destroy the existing player if it exists
      if (playerRef.current) {
        playerRef.current.destroy();
        playerRef.current = null;
      }

      // Initialize FluidPlayer
      playerRef.current = fluidPlayer(videoRef.current, {
        layoutControls: {
          controlBar: {
            autoHideTimeout: 3,
            animated: true,
            autoHide: true,
          },
          autoPlay: false,
          mute: true,
          allowTheatre: true,
        },
      });

      // Cleanup when the component unmounts or the video changes
      return () => {
        if (playerRef.current) {
          playerRef.current.destroy();
          playerRef.current = null;
        }
      };
    }
  }, [post.videoSrc, isVideoReady]); // Reinitialize FluidPlayer when videoSrc and video are ready

  // Scroll to top when a new post is loaded
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [post]);

  // Check when the video is ready
  const handleVideoLoad = () => {
    setIsVideoReady(true);
  };

  if (!post) return null;

  const { title, videoSrc, postImages } = post;
  const randomData = getRandomItems(data, 4);

  const handleImageClick = (imgSrc) => {
    setModalImage(imgSrc);
  };

  const closeModal = () => {
    setModalImage(null);
  };

  const handleCardClickWithLoading = (post) => {
    setIsLoading(true);
    setTimeout(() => {
      if (post.isPremium && !isLoggedIn) {
        setShowLogin(true); // Show login if the post is premium and user is not logged in
        setcheckLoggedIn(false);
      } else {
        handleCardClick(post); // Open the detail view
      }
      setIsLoading(false);
    }, 500);
  };

  return (
    checkLoggedIn ? (
      <div className="detail-container" key={post.title}>
        <button className="back-button" onClick={onBackClick}>
          Back
        </button>
        <div style={{ border: "1px solid #00000070", borderRadius: "inherit", padding: "inherit" }}>
          {videoSrc ? (
            <div className="video-container">
              <video
                ref={videoRef}
                key={videoSrc} // Ensures the video element is re-rendered when the source changes
                data-fluid-hd
                height="auto"
                width="auto"
                controls
                onLoadedData={handleVideoLoad} // Check when the video is loaded
              >
                <source src={videoSrc} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <a href={videoSrc} download className="download-button">
                <button style={{width:"100%"}}>Download Video</button>
              </a>
            </div>
          ) : postImages ? (
            <div className="images-container">
              <h2>{title}</h2>
              <div className="images-grid">
                {postImages.map((imgSrc, index) => (
                  <div key={index} className="image-wrapper">
                    <img
                      src={imgSrc}
                      alt={`Post ${index + 1}`}
                      onClick={() => handleImageClick(imgSrc)}
                    />
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="no-content">😕 Sorry, Video not available. Check back soon! 🌟</div>
          )}
          <div className="title">{title}</div>
          <div className="description" style={{ textAlign: "center" }}>{post.subTitle}</div>
        </div>

        {/* Recommended Section */}
        <div className="recommended-section">
          <h3 style={{ textAlign: "left" }}>Recommended</h3>
          {isLoading && <div className="loading-spinner-container"><div className="loading-spinner"></div></div>}
          <div className={`card-list ${isLoading ? 'loading' : ''}`}>
            {randomData.map((post) => (
              <Card
                key={post.title}
                imageSrc={post.imageSrc}
                title={post.title}
                subTitle={post.subTitle}
                category={post.category}
                videoSrc={post.videoSrc}
                postImages={post.postImages}
                isPremium={post.isPremium}
                onClick={() => handleCardClickWithLoading(post)}
              />
            ))}
          </div>
        </div>

        {/* Modal */}
        <Modal imageSrc={modalImage} onClose={closeModal} />
      </div>
    ) : (
      <>
      <div className="detail-container" key={post.title}>
      <button className="back-button" onClick={onBackClick}>
          Back
        </button>
        </div>
      <Login onLogin={handleLogin} />
      </>
    )
  );
};

export default Detail;
