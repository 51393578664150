import React, { useState, useEffect } from 'react';
import './Login.css';

const Login = ({ onLogin }) => {
  const [loginId, setLoginId] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const handleLogin = () => {
    // Replace 'your_login_id' and 'your_password' with actual values
    if (loginId === 'indianseries' && password === 'mms-video') {
      localStorage.setItem('isLoggedIn', 'true');
      onLogin(); 
      window.location.reload(); // Reload the page after login
    } else {
      alert('Invalid credentials. Please try again.');
    }
  };

  return (
    <div className="login-container">
      <div className="login-form">
        <h2>Login to Access All Premium Content</h2>
        <div className="instructions">
          <h3>Steps to Get Access:</h3>
          <ol>
            <li>Join our Telegram group: <strong><a href="https://t.me/+eYPOuNm4ZWwzMjA1" target="_blank" rel="noopener noreferrer">Click here to join the Telegram group</a></strong>.</li>
            <li>Once you've joined, You will get the login ID and password in the group by admin.</li>
            <li>Enter the login ID and password you received in the fields below to access premium content.</li>
            <li>You will also get access to the <strong><a href='https://scratch.indianseries.site/'>Tear Girl's Cloth</a></strong> in the group.</li>
          </ol>
        </div>

        {/* Input fields for Login */}
        <input
          type="text"
          placeholder="Login ID"
          value={loginId}
          onChange={(e) => setLoginId(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button onClick={handleLogin}>Login</button>

        {/* Telegram Support Contact */}
        <div className="support-contact">
          <p>For support, contact us on Telegram: <strong><a href="https://t.me/premium_contentrabbot" target="_blank" rel="noopener noreferrer">https://premium_contentrabbot</a></strong></p>
        </div>
      </div>
    </div>
  );
};

export default Login;
