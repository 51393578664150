import { React, useEffect } from 'react';
import './Card.css';

const Card = ({ imageSrc, title, subTitle, category, postImages, isPremium, onClick }) => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  let contentTag = postImages ? "Images" : "Video";
  let premiumTag = isPremium ? "Premium" : "Free";

  return (
    <div className="card" onClick={onClick}>
      <div className="card-image" style={{ backgroundImage: `url(${imageSrc})` }}>
        <div className="content-tag">{contentTag}</div>
        <div className={`premium-tag ${isPremium ? 'premium' : 'not-premium'}`}>
          {premiumTag}
        </div>
      </div>
      <div className="card-content">
        {/* <div className="card-category">{category}</div> */}
        <div className="card-title"><strong>{title}</strong></div>
        {/* <div className="card-subtitle">{subTitle}</div> */}
      </div>
    </div>
  );
};

export default Card;
